import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024 896">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" >
  
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M779.202148,658.282959 
	C756.385681,672.018311 732.235046,681.456421 706.333252,685.642578 
	C679.149231,690.035889 651.925659,689.612915 624.999023,683.065918 
	C591.008606,674.801453 560.712097,659.494690 535.037964,635.524109 
	C531.950256,632.641296 530.170471,633.409851 527.907227,636.407410 
	C515.566162,652.752930 503.101990,669.005554 490.678040,685.288513 
	C468.857391,713.886841 447.043213,742.490173 425.186279,771.060791 
	C424.230194,772.310547 423.489563,774.006775 421.156830,774.165100 
	C420.043152,770.538452 422.138184,767.442261 423.195435,764.418884 
	C433.132507,736.001770 443.285950,707.660217 453.388947,679.301208 
	C465.000793,646.706848 476.629364,614.118469 488.241913,581.524353 
	C495.332611,561.622192 502.417664,541.718018 509.482300,521.806580 
	C511.927612,514.914612 511.649170,514.235352 504.648376,513.928955 
	C478.003052,512.762756 451.338898,513.627686 424.684326,513.372559 
	C419.628296,513.324219 414.494354,513.974670 409.164154,512.746338 
	C410.175171,507.997162 413.614166,505.324310 415.841187,501.934845 
	C425.133789,487.791473 425.162323,487.806824 442.088409,486.971039 
	C443.752380,486.888824 445.420044,486.879730 447.086121,486.842316 
	C449.849548,486.780151 450.933990,485.295898 450.718353,482.638214 
	C450.260803,476.998596 450.332092,471.259033 449.257233,465.741486 
	C448.021271,459.397339 449.962433,454.594940 453.686218,449.629517 
	C463.967896,435.919495 473.863312,421.919708 483.893036,408.021179 
	C484.549805,407.111145 485.499420,406.257874 484.878571,404.204132 
	C478.885101,407.006561 475.694427,402.405579 471.835785,399.089630 
	C440.484436,372.147339 396.891235,372.160492 365.289520,399.074310 
	C364.021332,400.154358 362.836517,401.339905 361.515411,402.348602 
	C358.354370,404.762115 354.875244,405.159454 351.997345,402.308990 
	C348.979462,399.319855 349.721558,395.783630 352.448456,392.934753 
	C359.313568,385.762451 367.321503,379.996307 376.290283,375.849335 
	C409.397888,360.541199 441.505127,362.379089 472.239258,382.337036 
	C476.680389,385.221069 480.600098,388.918457 484.700378,392.312866 
	C487.012878,394.227325 487.421783,397.108582 487.670685,399.890503 
	C490.366547,399.947815 491.267395,398.152740 492.287048,396.785522 
	C499.059082,387.705444 505.640564,378.481445 512.513245,369.479279 
	C514.942017,366.297882 514.642456,364.172607 511.787903,361.458954 
	C490.978516,341.676758 466.406982,329.400085 438.030243,325.525360 
	C394.201447,319.540771 356.117188,332.221680 323.830139,362.395142 
	C320.379822,365.619598 316.231140,366.348053 313.601196,363.996826 
	C310.684540,361.389282 310.979828,356.585571 314.692596,352.970367 
	C330.581635,337.498871 349.161407,326.388062 370.101562,319.162445 
	C397.794495,309.606689 426.042175,308.685425 454.333099,315.212402 
	C477.190613,320.485840 497.675110,331.142731 515.539062,346.539154 
	C523.809143,353.666962 523.852661,353.727020 530.268677,345.240326 
	C535.391968,338.463593 540.240112,331.476837 545.434692,324.756775 
	C547.887146,321.584076 547.605469,319.576508 544.531494,316.871765 
	C518.387878,293.868652 488.218201,279.122009 454.025665,272.874664 
	C428.018188,268.122864 401.923615,268.359467 376.079437,274.120636 
	C348.022644,280.375031 322.719849,292.636078 300.257568,310.609985 
	C294.930969,314.872253 289.572906,319.108856 284.750244,323.989410 
	C281.090515,327.693024 277.500732,327.950562 274.636078,325.213501 
	C272.165558,322.853027 272.398651,318.160370 275.417969,314.792877 
	C280.326324,309.318512 286.051483,304.698303 291.772339,300.138824 
	C312.883362,283.313568 336.582428,271.325775 362.499207,264.024323 
	C389.396881,256.446472 416.789978,254.906128 444.607056,258.132690 
	C469.438477,261.012970 492.627563,268.690918 514.691284,280.045502 
	C528.356140,287.077820 540.790771,296.070465 552.471191,305.999725 
	C555.879333,308.896942 557.421509,308.509186 559.833557,305.187958 
	C574.610535,284.840973 589.534790,264.600983 604.405762,244.322281 
	C624.290405,217.206680 644.193176,190.104401 664.042603,162.963058 
	C671.910767,152.204453 679.637024,141.342087 687.480164,130.565048 
	C688.604797,129.019699 689.422424,127.024452 692.013794,126.573029 
	C693.044067,129.412445 691.474121,131.766510 690.509827,134.010269 
	C668.417419,185.416565 646.145081,236.745651 624.116455,288.179169 
	C607.403503,327.201477 590.975769,366.346008 574.439148,405.443787 
	C572.820068,409.271729 571.227295,413.116486 569.800293,417.018524 
	C567.973145,422.014709 568.623169,422.890533 573.866333,422.996033 
	C587.041626,423.261078 600.170959,422.198700 613.315552,421.597382 
	C633.438843,420.676849 653.560059,419.710724 673.680969,418.738129 
	C677.669006,418.545380 681.649841,418.205353 685.637146,417.992798 
	C687.916382,417.871338 690.260132,417.253723 693.334045,419.084961 
	C685.436523,432.178040 674.635071,443.062042 666.030579,456.564575 
	C687.459473,455.248688 699.855957,464.722260 700.120605,486.412781 
	C700.277100,499.235229 690.789246,509.434326 678.369812,512.312195 
	C669.891418,514.276855 662.286072,512.540771 655.099060,507.810699 
	C647.895264,503.069611 645.564697,495.381470 642.657715,486.450409 
	C618.567566,518.038208 595.264221,548.594360 571.958069,579.154236 
	C582.110229,584.998779 589.178040,593.438293 598.464722,599.023987 
	C611.816345,607.054504 625.708008,613.400574 640.998047,616.970459 
	C653.449097,619.877625 665.912537,621.358398 678.607178,620.649536 
	C702.859070,619.295166 725.234558,612.054199 745.587280,598.689148 
	C753.829773,593.276550 761.552734,587.274414 768.415405,580.143494 
	C770.997986,577.459839 774.164734,575.227173 778.131531,577.938049 
	C782.152649,580.686157 782.206238,585.274353 778.021057,589.487854 
	C769.419861,598.147339 759.964661,605.807434 749.494934,612.082825 
	C725.531433,626.446350 699.488220,634.255066 671.572205,633.883789 
	C632.560608,633.364929 598.327698,619.985413 569.781738,592.976440 
	C568.250366,591.527527 567.065063,589.712769 565.316956,587.577637 
	C559.226807,595.476196 553.263184,603.191650 547.324280,610.926025 
	C545.803833,612.906067 544.564697,615.144043 542.839172,616.915649 
	C539.643066,620.197266 540.147583,622.666382 543.463135,625.631836 
	C569.885742,649.264587 600.328308,665.249573 635.142700,671.706299 
	C682.029297,680.402039 726.608887,673.257568 768.120544,649.169861 
	C782.739380,640.687073 796.063965,630.528442 807.823486,618.348083 
	C811.725586,614.306335 815.651001,613.919006 818.671265,617.107544 
	C821.308899,619.892273 820.703430,623.885315 817.116028,627.678284 
	C805.938721,639.495789 793.133850,649.297424 779.202148,658.282959 
M685.567017,478.076477 
	C680.732910,469.713348 670.618591,467.044983 663.045288,472.134705 
	C656.157532,476.763733 654.845337,487.213654 660.269043,494.244781 
	C664.829224,500.156433 672.298584,501.466248 679.546875,497.625305 
	C686.086426,494.159943 688.396729,487.198303 685.567017,478.076477 
M646.569885,471.184845 
	C650.028198,464.751526 655.549072,460.512817 661.933655,456.866364 
	C655.812134,456.681458 649.932129,457.039276 644.067993,457.571442 
	C642.680908,457.697296 641.569153,458.794922 641.440613,460.242157 
	C640.942871,465.844421 641.730652,471.286499 643.318115,476.956268 
	C645.575867,475.441254 645.398682,473.371613 646.569885,471.184845 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M666.348022,578.950195 
	C651.389648,577.831238 637.764465,573.831482 625.169922,566.553772 
	C619.549194,563.305847 614.206360,559.608032 609.270081,555.367615 
	C608.269470,554.507935 607.303833,553.546875 606.549194,552.472900 
	C604.210022,549.143860 603.523071,545.623352 606.814209,542.582703 
	C609.974487,539.662903 613.400879,540.516052 616.199768,543.215576 
	C621.758362,548.576721 627.927734,553.030151 634.780212,556.543945 
	C647.779541,563.209717 661.477478,565.776306 676.170776,565.319763 
	C697.595276,564.653992 714.761230,555.475769 729.882385,541.240234 
	C733.024963,538.281677 737.208923,537.965393 739.649658,540.648743 
	C742.614502,543.908447 742.301697,547.239319 739.349670,550.420654 
	C727.571411,563.113770 712.943420,570.943237 696.464722,575.664551 
	C686.758667,578.445435 676.839111,579.167114 666.348022,578.950195 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M403.898651,484.282715 
	C392.148468,476.356506 387.629456,464.313721 391.187897,451.084137 
	C393.965424,440.758026 405.510437,431.378296 417.499695,431.457489 
	C432.633057,431.557434 443.508148,438.783142 446.931946,455.787079 
	C449.671936,469.394897 440.189026,483.537140 426.771942,487.257935 
	C418.797607,489.469330 411.366089,488.399994 403.898651,484.282715 
M404.773804,454.482147 
	C402.614502,462.190796 404.248138,468.235138 409.613983,472.390198 
	C413.549927,475.437958 421.881500,475.915131 426.172180,473.338501 
	C431.151581,470.348358 434.634338,462.647278 433.551544,457.021362 
	C432.400757,451.042236 427.395386,446.246948 421.395264,445.375275 
	C413.781677,444.269226 408.929504,446.765381 404.773804,454.482147 
z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
